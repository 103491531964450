import { useState } from "react";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import FormInput, { InputProps } from "./FormInput";
import { FieldValues, UseFormSetValue } from "react-hook-form";

/**
 * This is a controlled and designed form input field for only passwords
 *
 * @param errors
 * @param label
 * @param placeholder
 * @param register formcontrol with useForm
 * @param leftAddon JSX.Element
 * @param className
 * @returns
 */
function FormPassword<T extends FieldValues>({
  errors,
  register,
  name,
  setValue,
  ...rest
}: InputProps & {
  setValue: UseFormSetValue<T>;
}) {
  const [visibleReEnterPassword, setVisibleReEnterPassword] = useState(false);

  return (
    <FormInput
      {...rest}
      errors={errors}
      register={register}
      onChange={(e) => setValue(name as any, e.target.value as any)}
      type={visibleReEnterPassword ? "text" : "password"}
      rightAddon={
        <div className='flex absolute inset-y-0 right-4 items-center'>
          <div
            className='cursor-pointer'
            onClick={() => setVisibleReEnterPassword(!visibleReEnterPassword)}
          >
            {!visibleReEnterPassword ? (
              <BsEyeSlash className=' text-primary-25' />
            ) : (
              <BsEye className=' text-primary-25' />
            )}
          </div>
        </div>
      }
      name={name}
    />
  );
}

export default FormPassword;
