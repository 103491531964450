/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { HTMLInputTypeAttribute } from "react";
import { UseFormRegisterReturn } from "react-hook-form";
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  errors?: string;
  caption?: string;
  label?: string;
  placeholder?: string;
  register?: UseFormRegisterReturn;
  type?: HTMLInputTypeAttribute;
  leftAddon?: JSX.Element;
  rightAddon?: JSX.Element;
  name?: string;
  autoComplete?: string;
  className?: string;
  labelClassName?: string;
  inputClassName?: string;
  placeholderClassName?: string;
  leftAddonClassName?: string;
  rightAddonClassName?: string;
  labelRightAddon?: JSX.Element;
}

/**
 *
 * This is a controlled and designed form input field
 *
 * @param errors
 * @param label
 * @param placeholder
 * @param register formcontrol with useForm
 * @param type 'email' | 'text' etc
 * @param leftAddon JSX.Element
 * @param rightAddon JSX.Element
 * @param className
 * @param labelClassName {string}
 * @param inputClassName
 * @param leftAddonClassName
 * @param rightAddonClassName
 * @returns
 */
const FormInput: React.FC<InputProps> = ({
  errors,
  label,
  placeholder,
  register,
  caption,
  leftAddon,
  rightAddon,
  className,
  autoComplete,
  labelClassName,
  inputClassName,
  leftAddonClassName,
  placeholderClassName,
  rightAddonClassName,
  labelRightAddon,
  ...props
}) => {
  return (
    <div className={`${className ?? ""} w-full `}>
      {label && (
        <div className='flex flex-row gap-2'>
          <label
            className={`block text-gray-700 text-[10px] md:text-xs font-gellix font-medium mb-2 ${
              labelClassName || ""
            }`}
          >
            <div className='inline-flex flex-row relative'>
              {label}
              {props.required && (
                <span className='absolute -right-2 text-red-500 font-gellix font-semibold  text-sm capitalize'>
                  *
                </span>
              )}
            </div>
            {caption && (
              <span className='block text-[8px] md:text-[9px] text-primary-50 font-normal normal-case '>
                {caption}
              </span>
            )}
          </label>
          {labelRightAddon}
        </div>
      )}

      <div className={`relative`}>
        {leftAddon && (
          <div
            className={`flex absolute inset-y-0 left-0 items-center pointer-events-none ${leftAddonClassName}`}
          >
            {leftAddon}
          </div>
        )}

        <input
          className={`
            ${inputClassName}
             duration-300 rounded-md border-[1px] flex-1 appearance-none
            w-full py-3 px-4 bg-white text-primary placeholder:${
              placeholderClassName ? placeholderClassName : "text-primary-50"
            } text-base placeholder:text-base
            outline-0 border-primary-25 focus:border-primary disabled:bg-primary-10
            disabled:border-primary-25 disabled:cursor-not-allowed
            ${leftAddon ? "pl-10" : ""} ${rightAddon ? "pr-10" : ""}
          `}
          placeholder={placeholder}
          autoComplete={autoComplete}
          {...(register ?? {})}
          {...props}
          size={placeholder?.length}
        />

        {rightAddon && (
          <div className={`flex absolute inset-y-0 right-0 items-center ${rightAddonClassName}`}>
            {rightAddon}
          </div>
        )}
      </div>

      <span className=' text-fail text-xs w-full mt-2 font-gellix'>{errors ?? ""}</span>
    </div>
  );
};

export default FormInput;
