/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import AuthorizationLayout from "@gruene-brise/common-ui/lib/layout/AuthorizationLayout";
import UserSignInMethod from "@gruene-brise/common-ui/lib/webshop/UserSignInMethod";

import { useTranslation } from "react-i18next";
import FooterText from "./FooterText";
import Cart from "./Cart";

/**
 * User sign up form
 * @returns JSX.Element
 */

export function UserSignIn({
  onLogin,
  onRegister,
  hideHeader,
  hideDoctor,
  className,
}: {
  onLogin?(): void;
  onRegister?(): void;
  hideHeader?: boolean;
  hideDoctor?: boolean;
  className?: string;
}) {
  const { t } = useTranslation();
  return (
    <AuthorizationLayout
      title={
        <>
          <div>{t("Login")}</div>
          <div className='text-primary-100'>Apotheken-Marktplatz</div>
        </>
      }
      className={`w-11/12 xl:w-7/12 lg:w-7/12 flex flex-col content-center justify-center px-4 ${
        className ?? ""
      }`}
      footer={
        hideDoctor ? (
          <></>
        ) : (
          <div className='w-full mt-[150px]'>
            <FooterText />
          </div>
        )
      }
      hideHeader={hideHeader}
    >
      <UserSignInMethod onClose={onLogin} onRegister={onRegister} />
    </AuthorizationLayout>
  );
}

export default UserSignIn;
