import { TFunction } from "i18next";
import * as yup from "./yup";

/**
 * Manages the validation for login users
 */

/**
 * User Login Schema, only accepts email and password field
 */
export type Login = "email" | "password";

/**
 * User Login Schema, only accepts email and password field
 */
export type LoginProps = {
  [key in Login]?: string;
};

/**
 * User Login scheme expected default values. Email is undefined and Password is undefined
 */
export const LoginDefaultValues: LoginProps = {};

/**
 * Login Schema validation
 * email is a required field
 * password is a required field
 */
export const LoginSchema = (t: TFunction<"translation", undefined, "translation">) => {
  yup.setYupLocale(t);
  return yup.object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  });
};
