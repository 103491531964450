import { openRoutes } from "libs/data-access/src/lib/hooks/useAuth0";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import WebshopHeader, { WebshopHeaderProp } from "../navigation/WebshopHeader";

export interface WebshopNavigationProp extends WebshopHeaderProp {
  children: React.ReactNode;
  wrapper?: string;
  isDark?: boolean;
}

const WebshopNavigation = ({ children, showTopMenu, wrapper, isDark }: WebshopNavigationProp) => {
  const router = useRouter();

  useEffect(() => {
    if (!openRoutes.filter((i) => i === "/").includes(router.pathname)) return;
  }, [router]);

  return (
    <div
      className={` ${wrapper} h-screen w-screen bg-primary-10 min-h-screen scrollbar-hide overflow-hidden`}
    >
      <WebshopHeader isDark={isDark} showTopMenu={showTopMenu} />
      <main>{children}</main>
    </div>
  );
};

export default WebshopNavigation;
