/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { useAuthState } from "@gruene-brise/data-access/state/useAuthState";
import { useRouter } from "next/router";
import { Trans } from "react-i18next";

const FooterText = () => {
  const router = useRouter();
  const { doctorKey } = useAuthState();

  const moveUp = Boolean(doctorKey);

  return (
    <Trans>
      <div
        className={`relative ${
          moveUp ? "bottom-[20px]" : "bottom-[60px]"
        } w-full items-center flex flex-col font-gellix font-light text-xs text-center pb-10`}
      >
        <div className='text-center'>You’re a doctor and want to check prices & availabilties?</div>
        <div className='text-center'>
          Please{" "}
          <div
            onClick={(e) => {
              e.preventDefault();
              router.push("/doccheck");
            }}
            className='font-semibold underline cursor-pointer'
          >
            login here using DocCheck Login.
          </div>
        </div>
      </div>
      <div className='h-20' />
    </Trans>
  );
};

export default FooterText;
