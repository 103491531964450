const AuthorizationSuccess = ({ successDescription }: { successDescription?: React.ReactNode }) => {
  return (
    <div className='w-full mt-9'>
      <div className='items-center justify-center w-full relative flex'>
        <svg
          width='192'
          height='192'
          viewBox='0 0 192 192'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M191.906 96C191.906 148.968 148.968 191.906 96 191.906C43.0323 191.906 0.09375 148.968 0.09375 96C0.09375 43.0323 43.0323 0.09375 96 0.09375C148.968 0.09375 191.906 43.0323 191.906 96ZM84.9066 146.782L156.063 75.6253C158.479 73.2091 158.479 69.2913 156.063 66.875L147.313 58.1248C144.896 55.7082 140.978 55.7082 138.562 58.1248L80.5312 116.155L53.4381 89.0619C51.0219 86.6457 47.1041 86.6457 44.6875 89.0619L35.9372 97.8122C33.5209 100.228 33.5209 104.146 35.9372 106.562L76.1559 146.781C78.5725 149.198 82.49 149.198 84.9066 146.782Z'
            fill='#919C8B'
          />
        </svg>
      </div>

      <div className='w-full flex items-center flex-row justify-center'>
        <div className='font-gellix font-semibold text-md space-x-5 flex-wrap text-center  mt-10 w-full self-center flex justify-center items-center'>
          {successDescription}
        </div>
      </div>
    </div>
  );
};

export default AuthorizationSuccess;
