import { useTranslation } from "react-i18next";
import CircleBoxIcon from "../icon/CircleBoxIcon";

const Wallpaper = () => {
  const { t } = useTranslation();
  const menu = [
    t("Selected pharmacies"),
    t("Transparent prices"),
    t("Flexible online payment"),
    t("You in focus"),
  ];

  return (
    <div className='w-1/2 h-screen hidden xs:hidden sm:hidden md:hidden lg:block xl:block'>
      <div className='w-1/2 h-full top-0 bg-primary-10 fixed'>
        <img
          src='/images/bloomwell-login.png'
          alt='Banner'
          // width={1920} // Provide the width of the image
          // height={1080} // Provide the height of the image
          className='w-full h-full bg-primary-10 absolute'
        />
      </div>
    </div>
  );
};

export default Wallpaper;
