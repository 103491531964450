import { useRouter } from "next/router";
import React from "react";
import WebshopNavigation from "../navigation/WebshopNavigation";
import Wallpaper from "../webshop/Wallpaper";
import AuthorizationSuccess from "./AuthorizationSuccess";
import classNames from "classnames";

export interface AuthorizationLayoutProps {
  title: string | JSX.Element;
  children: React.ReactNode;
  onSubmit?(e: React.FormEvent<HTMLButtonElement>): void;
  options?: { successTitle?: string | JSX.Element; successDescription?: React.ReactNode };
  autoComplete?: string;
  isAdmin?: boolean;
  className?: string;
  wrapperClass?: string;
  footer?: JSX.Element;
  hideHeader?: boolean;
}

/**

The AuthorizationLayout component provides a basic layout for the authentication pages with an optional success message.
@param title The title to be displayed on the page.
@param children The child components to be rendered inside the layout.
@param onSubmit An optional submit handler for the form.
@param options An optional object containing success message title and description.
@param autoComplete An optional string to set the autocomplete attribute of the form.
@returns A React component that renders the authorization layout with the specified child components and success message.
*/
const AuthorizationLayout = ({
  title,
  children,
  onSubmit,
  options,
  autoComplete,
  isAdmin,
  className,
  wrapperClass,
  footer,
  hideHeader,
}: AuthorizationLayoutProps) => {
  const router = useRouter();
  const isSuccess = (router.query as any)?.success || (router.query as any)?.done;

  const form = (
    <form
      onSubmit={onSubmit as any}
      autoComplete={autoComplete}
      className={classNames(
        `z-50 items-center justify-center flex flex-col overflow-auto scrollbar-hide overflow-hidden`,
        {
          "w-full": hideHeader,
          "w-full xl:w-1/2 lg:w-1/2": !hideHeader,
        },
      )}
    >
      <div
        className={classNames(
          `w-full h-full flex flex-col items-center ${isSuccess ? "" : wrapperClass}`,
          {
            "bg-primary-10": !hideHeader,
          },
        )}
      >
        {isSuccess ? (
          <div className='items-center w-full h-full flex flex-col justify-center'>
            <div className=' text-center font-semibold font-gellix text-[28px] md:text-[32px] lg:text-[42px] leading-5 mb-7'>
              {options?.successTitle ?? title}
            </div>
            <div className={` ${className} w-full px-4 min-h-full`}>
              <AuthorizationSuccess successDescription={options?.successDescription ?? ""} />
            </div>
          </div>
        ) : (
          <>
            <div
              className={classNames(
                className,
                `font-semibold font-gellix text-[28px] md:text-[32px] lg:text-[42px] w-full`,
              )}
            >
              {title}
            </div>

            <div className={` ${className} w-full px-4 min-h-full`}>
              {children}
              {footer}
            </div>
          </>
        )}
      </div>
    </form>
  );

  if (hideHeader) {
    return form;
  }

  return (
    <WebshopNavigation showTopMenu={false} isDark>
      <div className='w-screen h-screen  overflow-hidden'>
        <div className='w-full h-full flex flex-row'>
          <Wallpaper />

          {form}
        </div>
      </div>
    </WebshopNavigation>
  );
};

export default AuthorizationLayout;
